import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import editIcon from "../../assets/icons/editIcn.svg";
import compareIcon from "../../assets/icons/compareIcn.svg";
import React, { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { getIndiaPollResults } from "../../redux/actions/privateApiAction";
import { useNavigate } from "react-router-dom";
import appicon from "../../assets/icons/Aam_Aadmi_Party_flag_(2).svg@2x.png";
import congFlag from "../../assets/icons/congFlag.svg";
import bjpFlag from "../../assets/icons/BJP_Flag.svg";
import othersFlag from "../../assets/icons/0thersflag.svg";

const MyPredictions = () => {
  const isVerified = localStorage.getItem("accessToken");
  const { indiaPollsResult } = useSelector((state) => state?.privateReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Data for Pie Chart
  const data = [
    {
      name: "APP",
      value: parseFloat(indiaPollsResult?.appResults) || 25,
      female: parseFloat(indiaPollsResult?.appFemaleVShares),
      male: parseFloat(indiaPollsResult?.appMaleVShares),
    },
    {
      name: "BJP",
      value: parseFloat(indiaPollsResult?.bjpResults) || 40,
      female: parseFloat(indiaPollsResult?.bjpFemaleVShares),
      male: parseFloat(indiaPollsResult?.bjpMaleVShares),
    },
    {
      name: "CON",
      value: parseFloat(indiaPollsResult?.incResults) || 15,
      female: parseFloat(indiaPollsResult?.incFemaleVShares),
      male: parseFloat(indiaPollsResult?.incMaleVShares),
    },
    {
      name: "OTH",
      value: parseFloat(indiaPollsResult?.othersResults) || 10,
      female: parseFloat(indiaPollsResult?.othersFemaleVShares),
      male: parseFloat(indiaPollsResult?.othersMaleVShares),
    },
  ];
  const COLORS = ["#FDF000", "#FF671F", "#4251A8", "#AAAAAA"];
  const partyData = [
    {
      name: "Aam Aadmi Party",
      result: indiaPollsResult?.appResults,
      color: "bg-gradient-to-r via-amber-200 from-[#FDF000] to-white",
      icon: appicon,
    },
    {
      name: "Bhartiya Janta Party",
      result: indiaPollsResult?.bjpResults,
      color: "bg-gradient-to-r via-[#FF671F] from-[#FF671F] to-orange-100",
      icon: bjpFlag,
    },
    {
      name: "Congress",
      result: indiaPollsResult?.incResults,
      color: "bg-gradient-to-r via-indigo-400 from-indigo-800 to-indigo-200",
      icon: congFlag,
    },
    {
      name: "Others",
      result: indiaPollsResult?.othersResults,
      color: "bg-gradient-to-r from-gray-500 to-white",
      icon: othersFlag,
    },
  ];

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!isVerified) {
      navigate("/login");
    }
    dispatch(getIndiaPollResults()).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="bg_home flex flex-col pb-8 h-auto">
      <div className="bg_home md:bg-[#F7F8FA] px-4 min-[955px]:px-40 flex flex-col pb-6 md:py-5 h-auto">
        <div className="p-2 md:p-6 bg-white border rounded-xl shadow-md">
          <div className="">
            <div className="">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row justify-between items-center mb-14">
                  <div className="grid grid-cols-1 md:grid-cols-2 w-full lg:w-[80%] items-center">
                    <div className="space-y-4 md:hidden">
                      {partyData.map((party, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 ms-5"
                        >
                          <div className="min-w-14 max-w-14 mt-3">
                            <img src={party.icon} alt={party.name} />
                          </div>
                          <div className="text-[14px] font-semibold">
                            <p className="mb-1">{party.name}</p>
                            <div
                              className={`${
                                party.color
                              } min-w-[3.5rem] max-w-[26rem]  w-[${parseInt(
                                Math.max(5, party?.result)
                              )}rem] flex justify-end  font-bold py-1 px-2`}
                              style={{
                                width: `${Math.max(5, party?.result)}vw`,
                              }}
                            >
                              {party?.result}%
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="space-y-4 hidden md:block">
                      {partyData.map((party, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 ms-5"
                        >
                          <div className="min-w-14 max-w-14 mt-3">
                            <img src={party.icon} alt={party.name} />
                          </div>
                          <div className="text-[14px] font-semibold">
                            <p className="mb-1">{party.name}</p>
                            <div
                              className={`${
                                party.color
                              } min-w-[4rem] max-w-[26rem]  w-[${parseInt(
                                Math.max(5, party?.result)
                              )}rem] flex justify-end  font-bold py-1 px-2`}
                              style={{
                                width: `${
                                  Math.max(5, party?.result) / 2.1 ||
                                  16 - index * 4
                                }vw`,
                              }}
                            >
                              {party?.result || 16 - index * 4}%
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {!loading ? (
                      <div className="w-[75vw] md:w-[38vw] flex justify-end">
                        <PieChart width={680} height={300}>
                          <Pie
                            data={data}
                            cx={300}
                            cy={140}
                            innerRadius={70}
                            outerRadius={125}
                            fill="#8884d8"
                            paddingAngle={5}
                            labelLine={false}
                            dataKey="value"
                            label={({
                              cx,
                              cy,
                              midAngle,
                              innerRadius,
                              outerRadius,
                              index,
                            }) => {
                              const RADIAN = Math.PI / 180;
                              const radius =
                                innerRadius + (outerRadius - innerRadius) * 1.1;
                              const x =
                                cx + radius * Math.cos(-midAngle * RADIAN);
                              const y =
                                cy + radius * Math.sin(-midAngle * RADIAN);
                              const sliceData = data[index];
                              return (
                                <g>
                                  <circle
                                    cx={x}
                                    cy={y - 1}
                                    r={25}
                                    fill="red"
                                    stroke="white"
                                    strokeWidth={4}
                                  />
                                  <text
                                    x={x}
                                    y={y - 1}
                                    fill="white"
                                    textAnchor="middle"
                                    dominantBaseline="central"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`${sliceData?.value}`}
                                  </text>
                                </g>
                              );
                            }}
                          >
                            {data?.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </div>
                    ) : (
                      <>
                        <div className="w-[85vw] md:w-[38vw] h-[20.5rem] flex justify-center items-center relative">
                          <div className="skeleton-loader size-60 rounded-full relative">
                            <div className="w-16 h-6 rotate-12 bg-white absolute top-24"></div>
                            <div className="w-6 h-20 rotate-[60deg] bg-white absolute top-[3.5rem] left-[12rem]"></div>
                            <div className="w-6 h-20 rotate-[150deg] bg-white absolute bottom-[0.5rem] left-[10rem]"></div>
                            <div className="size-36 rounded-full bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:flex justify-between items-center mb-4">
            <div className="my-5">
              <FormControl className="w-[8rem] md:w-[20rem]" variant="outlined">
                <InputLabel id="select-State-label">Select State</InputLabel>
                <Select
                  labelId="select-State-label"
                  id="selectState"
                  name="state"
                  label="Select State"
                  sx={{ borderRadius: "0.75rem" }}
                >
                  <MenuItem value="delhi">Delhi</MenuItem>
                  <MenuItem value="uttarpradesh">Uttar Pradesh</MenuItem>
                  <MenuItem value="gujarat">Gujarat</MenuItem>
                  <MenuItem value="maharashtra">Maharashtra</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="flex gap-2 text-[14px]">
              <button className="px-4 py-2 border rounded-xl text-white bg-[#012169]">
                Edit
              </button>
              <button className="px-4 py-2 bg-[#4252A8] text-white rounded-xl">
                Compare
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div className="h-[20rem]  flex flex-col p-2 md:p-5 ">
              <div className="flex justify-start items-baseline gap-4 my-5 ">
                <p>50% </p>
                <div className="w-full border"></div>
              </div>
              <div className="flex justify-start items-baseline gap-4 my-5">
                <p>38% </p>
                <div className="w-full border "></div>
              </div>
              <div className="flex justify-start items-baseline gap-4 my-5">
                <p>25% </p>
                <div className="w-full border "></div>
              </div>
              <div className="flex justify-start items-baseline gap-4 my-5">
                <p>13% </p>
                <div className="w-full border "></div>
              </div>
              <div className="flex justify-start items-baseline gap-4 my-5 relative ">
                <p>0% </p>
                <div className="w-full border-2 border-gray-500 "></div>
                <div
                  className={` absolute w-[2rem] min-[580px]:w-[3rem]  h-[9rem] bottom-2 left-[2.5rem] min-[580px]:left-[13.5rem] lg:left-[23.5rem] bg-[#FDF000] text-white md:px-2 `}
                >
                  28%
                </div>
                <div
                  className={` absolute w-[2rem] min-[580px]:w-[3rem]  h-[12rem] bottom-2 left-[5rem] min-[580px]:left-[17.5rem] lg:left-[27rem] bg-[#FF671F] text-white md:px-2`}
                >
                  {" "}
                  38%
                </div>
                <div
                  className={` absolute w-[2rem] min-[580px]:w-[3rem]  h-[7rem] bottom-2 left-[7.5rem] min-[580px]:left-[21.5rem] lg:left-[30.5rem] 
                bg-[#4252A8] md:px-2 text-white `}
                >
                  23%
                </div>
                <div
                  className={`absolute w-[2rem] min-[580px]:w-[3rem]  h-[3rem] bottom-2 left-[10rem] min-[580px]:left-[25.5rem] lg:left-[34rem] bg-[#AAAAAA] md:px-2 text-white `}
                >
                  11%
                </div>
              </div>
            </div>
            <div className="flex justify-center items-end gap-2 md:gap-7 mt-3 font-bold text-[12px]">
              <div className="flex items-center gap-1">
                {" "}
                <div className="h-3 w-4 bg-[#FDF000]"></div>AAP
              </div>
              <div className=" flex items-center gap-1 text-[#FF671F]">
                {" "}
                <div className="h-3 w-4 bg-[#FF671F]"></div>BJP
              </div>
              <div className=" flex items-center gap-1 text-[#4252A8]">
                {" "}
                <div className="h-3 w-4 bg-[#4252A8]"></div>CON
              </div>
              <div className=" flex items-center gap-1 text-[#AAAAAA]">
                {" "}
                <div className="h-3 w-4 bg-[#AAAAAA]"></div>OTH
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPredictions;
