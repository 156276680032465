import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrediction } from "../../redux/actions/privateApiAction";
import PredictionContext from "../../context/predictionFlow/predictionContext";

const CountryListingCom = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrediction());
  }, []);

  const { prediction, setPrediction } = useContext(PredictionContext);
  const { predictionResponse } = useSelector((state) => state?.privateReducer);

  const handleCountrySelect = (id, name) => {
    setPrediction({
      ...prediction,
      selectCountry: {
        id: id,
        name: name,
      },
    });
  };

  return (
    <div>
      <div className="text-[12px] text-[#7F8FA4] my-2">
        Select country to start prediction
      </div>
      <div className="flex flex-col gap-5 py-2 my-2 overflow-y-auto max-h-[calc(100vh-80px)] md:max-h-[calc(100vh-100px)]">
        <div className="">
          <div className="my-2 grid grid-cols-1 min-[380px]:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2">
            {predictionResponse?.data?.map((res) => {
              return (
                <div
                  key={res?.id}
                  className="m-auto  min-[380px]:m-0 size-[160px] hover:bg-white bg-[#F7F8FA] hover:border-2 border-red-500 cursor-pointer rounded-xl flex justify-center items-center flex-col"
                  onClick={() =>
                    handleCountrySelect(res?.country?.country_Id, res?.name)
                  }
                >
                  <img src={res?.image} alt="indian-flag" width={80} />
                  <p className="mt-5 text-[12px] font-semibold">{res?.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryListingCom;
