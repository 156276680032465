import React, { useState } from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

const PredictionTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState(null);

  const sortedData = [...data].sort((a, b) => {
    if (!sortConfig) return 0;
    if (a[sortConfig.key] < b[sortConfig.key])
      return sortConfig.direction === "asc" ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key])
      return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });
  const requestSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };
  const getSortIcon = (key) => {
    if (!sortConfig || sortConfig.key !== key) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300">
          <thead className="bg-black text-white border-black">
            <tr>
              <th
                className="px-2 py-4 text-left cursor-pointer text-[12px] font-medium "
                onClick={() => requestSort("id")}
              >
                <div className="flex justify-start gap-2 items-center">
                  Sr. No {getSortIcon("id")}
                </div>
              </th>
              <th
                className="px-2 py-4 text-left cursor-pointer text-[12px] font-medium "
                onClick={() => requestSort("country")}
              >
                <div className="flex justify-start gap-2 items-center">
                  Country {getSortIcon("country")}
                </div>
              </th>
              <th
                className="px-2 py-4 text-left cursor-pointer text-[12px] font-medium "
                onClick={() => requestSort("state")}
              >
                <div className="flex justify-start gap-2 items-center">
                  State {getSortIcon("state")}
                </div>
              </th>
              <th
                className="px-2 py-4 text-left cursor-pointer text-[12px] font-medium "
                onClick={() => requestSort("creationDate")}
              >
                <div className="flex justify-start gap-2 items-center">
                  Date of Creation {getSortIcon("creationDate")}
                </div>
              </th>
              <th
                className="px-2 py-4 text-left cursor-pointer text-[12px] font-medium"
                onClick={() => requestSort("revisedDate")}
              >
                <div className="flex justify-start gap-2 items-center">
                  Revised on {getSortIcon("revisedDate")}
                </div>
              </th>
              <th className="px-2 py-4 text-left text-[12px] font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, idx) => (
              <tr key={item.id} className="bg-white border-b border-gray-300">
                <td
                  className={`p-2 text-[12px] ${
                    idx % 2 === 0 ? "bg-[#F3F5F6]" : "bg-[#E6E6E6]"
                  }`}
                >
                  {item.id}
                </td>
                <td
                  className={`p-2 text-[12px] ${
                    idx % 2 === 0 ? "bg-[#F3F5F6]" : "bg-[#E6E6E6]"
                  }`}
                >
                  {item.country}
                </td>
                <td
                  className={`p-2 text-[12px] ${
                    idx % 2 === 0 ? "bg-[#F3F5F6]" : "bg-[#E6E6E6]"
                  }`}
                >
                  {item.state}
                </td>
                <td
                  className={`p-2 text-[12px] ${
                    idx % 2 === 0 ? "bg-[#F3F5F6]" : "bg-[#E6E6E6]"
                  }`}
                >
                  {item.creationDate}
                </td>
                <td
                  className={`p-2 text-[12px] ${
                    idx % 2 === 0 ? "bg-[#F3F5F6]" : "bg-[#E6E6E6]"
                  }`}
                >
                  {item.revisedDate}
                </td>
                <td
                  className={`p-2 text-[12px] ${
                    idx % 2 === 0 ? "bg-[#F3F5F6]" : "bg-[#E6E6E6]"
                  }`}
                >
                  <button className="bg-[#012169] text-white px-4 py-2 rounded-2xl flex items-center">
                    <span className="mr-1">👁</span> View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PredictionTable;
