import React, { useContext, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getElectionType,
  getStateById,
} from "../../redux/actions/privateApiAction";
import PredictionContext from "../../context/predictionFlow/predictionContext";
import { useNavigate } from "react-router-dom";

const ElectionTypeComp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleElectionType = (data) => {
  //   setPrediction((prevState) => ({
  //     ...prevState,
  //     electionTypeData: { ...prevState.electionTypeData, ...data },
  //   }));
  // };

  const { prediction, setPrediction } = useContext(PredictionContext);
  useEffect(() => {
    if (prediction.selectCountry?.id) {
      dispatch(getStateById(prediction.selectCountry?.id));
      dispatch(getElectionType(prediction.selectCountry?.id));
    }
  }, []);

  const capitalize = (name) => {
    if (!name) return "";
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const { getElectionTypeResponse, getStateByIdResponse } = useSelector(
    (state) => state?.privateReducer
  );

  const handleStateSelection = (name, value) => {
    setPrediction((prevState) => ({
      ...prevState,
      electionTypeData: { ...prevState.electionTypeData, ...{ [name]: value } },
    }));
  };
  const handleTypeSelection = (name, value) => {
    setPrediction((prevState) => ({
      ...prevState,
      electionTypeData: { ...prevState.electionTypeData, ...{ [name]: value } },
    }));
    navigate("/prediction/new");
  };

  return (
    <div>
      <div className="flex flex-col gap-5 py-2 my-2 overflow-y-auto max-h-[calc(100vh-80px)] md:max-h-[calc(100vh-100px)]">
        <div>
          <div className="my-5">
            {/* Select State Dropdown */}
            <FormControl className="w-[15rem]" variant="outlined">
              <InputLabel id="select-State-label">Select State</InputLabel>
              <Select
                labelId="select-State-label"
                id="selectState"
                name="state"
                label="Select State"
                value={prediction.electionTypeData?.state || ""}
                sx={{
                  borderRadius: "15px",
                }}
                onChange={(event) =>
                  handleStateSelection("state", event.target.value)
                }
              >
                {getStateByIdResponse?.data?.map((state) => {
                  return (
                    <MenuItem value={state?.id} key={state?.id}>
                      {capitalize(state?.name)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          {prediction.electionTypeData?.state !== null && (
            <>
              {/* Election Type Selection */}
              <p className="my-5 text-[21px] font-semibold">
                Select Election Type
              </p>
              {/* Election Type Cards */}
              <div className="my-2 grid grid-cols-1 min-[380px]:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2">
                {getElectionTypeResponse?.data?.map((type, index) => (
                  <div
                    key={type?.id}
                    className="m-auto min-[380px]:m-0 size-[160px] hover:bg-white bg-[#F7F8FA] hover:border-2 border-red-500 cursor-pointer rounded-xl flex justify-center items-center flex-col"
                    onClick={() => handleTypeSelection("type", type?.name)}
                  >
                    <img src={type.image} alt={type.name} width={80} />
                    <p className="mt-5 text-[12px] font-semibold">
                      {type.name}
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElectionTypeComp;
