import React, { useContext, useEffect, useState } from "react";
import charticon from "../../assets/icons/Path 23145.svg";
import PredictionTable from "../../components/PridictionComp/predictionTable";
import CountryListingCom from "../../components/PridictionComp/countryListingCom";
import ElectionTypeComp from "../../components/PridictionComp/electionTypeComp";
import { useDispatch, useSelector } from "react-redux";
import { getPrediction } from "../../redux/actions/privateApiAction";
import PredictionContext from "../../context/predictionFlow/predictionContext";
import { useNavigate } from "react-router-dom";

const predictionData = [
  {
    id: 1,
    country: "United States of America",
    state: "Arizona",
    creationDate: "15/12/2024",
    revisedDate: "20/12/2024",
  },
  {
    id: 2,
    country: "United States of America",
    state: "New Mexico",
    creationDate: "15/12/2024",
    revisedDate: "20/12/2024",
  },
];

const PredictionActions = ({ onNewPrediction, navigate }) => (
  <div className="md:flex justify-between items-center w-full flex-wrap my-2 md:my-0">
    {}
    <div className="md:flex gap-2 my-2 md:my-0">
      {[
        "Completed Predictions",
        "My Live Predictions",
        "Followed Predictions",
      ]?.map((label, index) => (
        <div
          key={index}
          className="text-[12px] border p-2 rounded-2xl cursor-pointer hover:shadow-sm transition-all flex gap-2 items-center my-2 md:my-0 max-w-[15rem]"
          onClick={() =>
            navigate(
              label === "My Live Predictions" && "/prediction/my_predictions"
            )
          }
        >
          <img src={charticon} alt="chart-icon" width={12} />
          {label}
        </div>
      ))}
    </div>

    {onNewPrediction && (
      <div
        className="text-[12px] border p-2 rounded-2xl cursor-pointer bg-[#4252A8] text-white hover:shadow-sm transition-all flex gap-2 items-center  max-w-[15rem]"
        onClick={onNewPrediction}
        // onClick={() => navigate("/prediction/country")}
      >
        <img src={charticon} alt="chart-icon" width={12} />
        New Prediction
      </div>
    )}
  </div>
);

const PredictionListing = () => {
  const { predictionResponse } = useSelector((state) => state?.privateReducer);
  const { prediction, setPrediction } = useContext(PredictionContext);
  const navigate = useNavigate();

  console.log("predictionResponse ", prediction);

  const [state, setState] = useState({
    newPrediction: false,
    selectCountry: null,
    electionTypeData: { state: null, type: null },
    prediction: null,
    freshPredict: false,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrediction());
  }, []);

  const handleSelectPrediction = (val) => {
    setPrediction({ ...prediction, prediction: val });
    setState({ ...state, prediction: val });
  };
  const handleFreshPrediction = (val) => {
    setPrediction({ ...prediction, freshPredict: val });
    setState({ ...state, freshPredict: val });
  };

  const handleNewPrediction = () => {
    setState({ ...state, newPrediction: true });
    setPrediction({ ...prediction, newPrediction: true });
  };
  // const handleSelectCountry = (country) => {
  //   setPrediction({ ...prediction, selectCountry: country });
  //   setState({ ...state, selectCountry: country });
  // };

  const handleElectionType = (data) => {
    setPrediction((prevState) => ({
      ...prevState,
      electionTypeData: { ...prevState.electionTypeData, ...data },
    }));
    // setState((prevState) => ({
    //   ...prevState,
    //   electionTypeData: { ...prevState.electionTypeData, ...data },
    // }));
  };

  const renderStep = () => {
    if (!prediction.newPrediction) {
      return <PredictionTable data={predictionData} />;
    }

    if (!prediction.selectCountry) {
      return <CountryListingCom />;
    }
    if (
      !prediction.electionTypeData?.prediction ||
      !prediction.electionTypeData?.type
    ) {
      return <ElectionTypeComp />;
    }
  };

  return (
    <div className="bg_home flex flex-col pb-8 h-auto">
      <div className="bg_home md:bg-[#F7F8FA] px-4 min-[955px]:px-40 flex flex-col pb-6 md:py-5 h-auto">
        <div className="bg-white w-full rounded-xl shadow-sm md:p-5">
          <div className="">
            <PredictionActions
              onNewPrediction={handleNewPrediction}
              navigate={navigate}
            />
          </div>
          <div className="my-2 text-[12px] text-[#7F8FA4] flex">
            {prediction?.selectCountry?.name && (
              <div className="">{prediction?.selectCountry?.name}</div>
            )}
            {prediction?.electionTypeData?.prediction &&
              prediction?.electionTypeData?.type && (
                <div className="mx-1">
                  {`> ${
                    prediction?.electionTypeData?.prediction
                  }  > ${prediction?.electionTypeData?.type.replace(
                    "_",
                    " "
                  )}`}{" "}
                </div>
              )}
            {prediction?.prediction && (
              <div className="mx-1">{`> ${prediction?.prediction}`}</div>
            )}
            {prediction?.freshPredict && (
              <div className="mx-1">{`> fresh/new prediction > my prediction`}</div>
            )}
          </div>
          <div className="mt-3">{renderStep()}</div>
        </div>
      </div>
    </div>
  );
};

export default PredictionListing;
