import React, { useContext, useState } from "react";
import { BarChart } from "recharts";
import BarChartComp from "./myPredictions";
import PredictionContext from "../../context/predictionFlow/predictionContext";
import { useNavigate } from "react-router-dom";

const NewPrediction = ({ freshPredict }) => {
  const [data, setData] = useState([
    // {
    //   category: "Best Pollster Overall",
    //   AAP: 45,
    //   BJP: 49,
    //   INC: 4,
    //   Others: 4,
    //   Contribution: 0,
    // },
    { category: "Male", AAP: 47, BJP: 49, INC: 3, Others: 3, Contribution: 25 },
    {
      category: "Female",
      AAP: 44,
      BJP: 50,
      INC: 3,
      Others: 3,
      Contribution: 35,
    },
    {
      category: "Hindu",
      AAP: 48,
      BJP: 49,
      INC: 2,
      Others: 2,
      Contribution: 40,
    },
    { category: "Sikh", AAP: 48, BJP: 49, INC: 3, Others: 3, Contribution: 69 },
    {
      category: "Muslim",
      AAP: 44,
      BJP: 52,
      INC: 4,
      Others: 4,
      Contribution: 9,
    },
    { category: "OBC", AAP: 48, BJP: 49, INC: 3, Others: 3, Contribution: 69 },
    { category: "SC", AAP: 44, BJP: 52, INC: 4, Others: 4, Contribution: 9 },
  ]);

  const navigate = useNavigate();

  const { prediction, setPrediction } = useContext(PredictionContext);

  const handleFreshPrediction = (val) => {
    setPrediction({ ...prediction, freshPredict: val });
    navigate("/prediction/my_predictions");
    // setState({ ...state, freshPredict: val });
  };

  const handleChange = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
  };

  const resetValues = () => {
    setData(
      data.map((row) => ({
        ...row,
        Contribution: 0,
        AAP: 0,
        BJP: 0,
        INC: 0,
        Others: 0,
      }))
    );
  };

  return (
    <>
      <div className="bg_home flex flex-col pb-8 h-auto">
        <div className="bg_home md:bg-[#F7F8FA] px-4 min-[955px]:px-40 flex flex-col pb-6 md:py-5 h-auto">
          <div className="bg-white w-full rounded-xl shadow-sm md:p-5">
            {!freshPredict ? (
              <div className="p-2 md:p-5">
                <h2 className="text-2xl font-semibold mb-4 text-[18px]">
                  Fresh/New Prediction
                </h2>
                <div className=" rounded-2xl border-2 shadow-lg py-4 px-7 bg-white overflow-auto no-scrollbar">
                  <table className="w-full border-collapse text-[14px] ">
                    <thead>
                      <tr className="text-left">
                        <th className="p-2 min-w-[25rem]"> </th>
                        <th className="p-2">AAP</th>
                        <th className="p-2">BJP</th>
                        <th className="p-2">INC</th>
                        <th className="p-2">Others</th>
                        <th className="p-2">Contribution</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0 ? "bg-[#FCF3F0]" : "bg-[#E7F7FD]"
                          }`}
                        >
                          <td className="py-2 px-6 font-semibold">
                            {row?.category}
                          </td>
                          {["AAP", "BJP", "INC", "Others", "Contribution"].map(
                            (field) => (
                              <td key={field} className="p-2 ">
                                <input
                                  type="text"
                                  disabled={field === "Contribution"}
                                  value={`${row[field]}%`}
                                  className={`w-[5rem] text-center p-1  rounded ${
                                    field === "Contribution" && "font-semibold"
                                  } `}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      field,
                                      e.target.value.replace("%", "")
                                    )
                                  }
                                />
                              </td>
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-end gap-3 mt-4 text-[14px]">
                    <button
                      className="bg-black text-white px-6 py-2 rounded-xl"
                      onClick={resetValues}
                    >
                      Reset
                    </button>
                    <button
                      className="bg-[#4252A8] text-white px-6 py-2 rounded-xl"
                      onClick={() => handleFreshPrediction(true)}
                    >
                      Save & submit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {freshPredict && (
                  <div className="mt-8">
                    <BarChartComp />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPrediction;
